const images = {
    logo: require('./logo.png'),
    leafBranch1: require('./leaf-branch-1.png'),
    leafBranch2: require('./leaf-branch-2.png'),
    leafBranch3: require('./leaf-branch-3.png'),
    leafBranch4: require('./leaf-branch-4.png'),
    homeImg: require('./home-sushi-rolls.png'),
    sushiTitle: require('./home-sushi-title.png'),
    aboutSushiTitle: require('./about-sushi-title.png'),
    aboutSushi: require('./about-sushi.png'),
    popularOnigiri: require('./popular-onigiri.png'),
    popularSpringRols: require('./popular-spring-rols.png'),
    popularSushiRolls: require('./popular-sushi-rolls.png'),
    spinachLeaf: require('./spinach-leaf.png'),
    recentlySalmonSushi: require('./recently-salmon-sushi.png'),
    newsletterSushi: require('./newsletter-sushi.png'),
    springOnion: require('./spring-onion.png'),
    footerCard1: require('./footer-card-1.png'),
    footerCard2: require('./footer-card-2.png'),
    footerCard3: require('./footer-card-3.png'),
    footerCard4: require('./footer-card-4.png'),
}
export default images